
#withdraw-balance h2 {
    margin-bottom: 2rem;
}

#withdraw-balance .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#withdraw-balance .confirm-btn {
    width: 100%;
    margin-top: 2rem;
}

#withdraw-balance .balance-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#withdraw-balance .balance-wrapper .balance {
    margin-top: 1rem;
    color: var(--primary);
    font-size: 1.25rem;
    font-weight: bold;
}

#withdraw-balance .balance-wrapper .address {
    margin-top: 1rem;
    color: #969696;
    font-size: 0.75rem;
}

#withdraw-balance .balance-wrapper .balance-breakdowns {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

#withdraw-balance .balance-wrapper .balance-breakdowns .title {
    text-align: center;
}

#withdraw-balance .balance-wrapper .balance-breakdowns .breakdown-item {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

#withdraw-balance .balance-wrapper .balance-breakdowns .breakdown-item .description {
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
}

#withdraw-balance .balance-wrapper .balance-breakdowns .breakdown-item .description label {
}

#withdraw-balance .balance-wrapper .balance-breakdowns .breakdown-item .description span:nth-child(2) {
    font-weight: bold;
}

#withdraw-balance .balance-wrapper .balance-breakdowns .breakdown-item .description span:nth-child(4) {
    color: var(--primary);
    font-weight: bold;
}

#withdraw-balance .balance-wrapper .balance-breakdowns .breakdown-item .withdraw-btn {
    width: min-content;
    margin: 0;
}
