.default-btn {
    text-decoration: none;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: bold;
    background: #000;
    color: var(--secondary);
    width: max-content;
    padding: 0.5rem 1.7rem;
    cursor: pointer;
    position: relative;
    transform-origin: right;
    transition: all 0.3s ease;
}

.default-btn:disabled, .secondary-btn:disabled {
    background: var(--grey);
    cursor: not-allowed;
}

.default-btn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--primary);
    transition: transform 0.5s;
    transform-origin: right;
    transform: scaleX(0);
    z-index: 0;
    border-radius: 0.25rem;
}

.default-btn:enabled:hover:before {
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 0.5s;
}

.default-btn span {
    position: inherit;
    z-index: 1;
}

.secondary-btn {
    padding: 14px;
    background: #000000;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.5s;
}

.secondary-btn:enabled:hover {
    background-color: var(--primary);
    transition: 0.5s;
}
