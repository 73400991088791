.progress-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
}

.progress-item .label {
    border-radius: 100%;
    border: 2px solid var(--grey);
    display: flex;
    width: 2rem;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    color: var(--primary);
    font-weight: bold;
}

.progress-item .label.checked {
    color: #ffffff;
    background: var(--primary);
    border: 2px solid var(--primary);
}

.progress-item .label.error {
    color: #ffffff;
    background: #ff4c4c;
    border: 2px solid #ff4c4c;
}

.progress-item .title {
    font-weight: bold;
}

.progress-item .default-spinner {
    position: absolute;
}
