#withdraw-balance {
    height: 100%;
    display: flex;
    align-items: center;
}

#withdraw-balance h2 {
    margin-bottom: 2rem;
}

#withdraw-balance .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#withdraw-balance .confirm-btn {
    width: 100%;
    margin-top: 2rem;
}

#withdraw-balance .balance-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#withdraw-balance .balance-wrapper .balance {
    margin-top: 1rem;
    color: var(--primary);
    font-size: 1.25rem;
    font-weight: bold;
}

#withdraw-balance .balance-wrapper .address {
    margin-top: 1rem;
    color: #969696;
    font-size: 0.75rem;
}
