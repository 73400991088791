
#error-page .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#error-page p {
    text-align: center;
    line-height: 2;
    margin-top: 1rem;
}

#error-page #back-btn {
    width: 100%;
    margin-top: 1rem;
}
