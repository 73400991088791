
#place-offering h2 {
    margin-bottom: 2rem;
}

#place-offering .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#place-offering .progress-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

#place-offering .confirm-btn {
    width: 100%;
    margin-top: 2rem;
}
