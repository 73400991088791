

#wallet-page .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#wallet-page h2 {
    margin-bottom: 2rem;
}

#wallet-page .address {
    color: var(--primary);
    font-weight: bold;
    word-break: break-all;
}

#wallet-page .confirm-btn {
   width: 100%;
    margin-top: 2rem;
}

#wallet-page .text {
    margin-top: 2rem;
}
