
#sign-offering h2 {
    margin-bottom: 2rem;
}

#sign-offering p {
    text-align: center;
}

#sign-offering .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#sign-offering .progress-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

#sign-offering .confirm-btn {
    width: 100%;
    margin-top: 2rem;
}
