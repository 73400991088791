.default-card {
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
    margin: 0 auto;
    background-color: #fff;
    padding: 40px;
    max-width: 90%;
   
}

@media (max-width: 415px){
    .default-card{
        padding: 40px 30px;
    }
}