#close-offering h2 {
    margin-bottom: 2rem;
}

#close-offering .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_boxs{
    margin-top: 1.4rem;
}
.term_boxs{
    margin-bottom: 1.2rem;
    display: flex;

}
.term_boxs label{
    cursor: pointer !important;
}
#close-offering .confirm-btn {
    width: 100%;

}
.terms_label{
    color: #8f8cff;
    text-decoration:none;
    padding-left: 3px;
}
@media (max-width: 415px){
    .term_boxs{
        display: block;
    }
}
