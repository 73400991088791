#processing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#processing span {
    margin-top: 1rem;
    font-weight: bold;
}
