body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
.default-card{
  position: relative;
}
.wraps{
  height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

}
.logo_imgs{
  margin-bottom: 40px;
}
.logo_imgs img{
  display: inline-block;
  height: 50px;
 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #8f8cff;
  --secondary: #ffffff;
  --grey: #cccccc;
}

.login_tip_overlay{
  /* position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2); */
}
.login_tip_dialog{
   position: fixed;
    top: 20px;
    right: 20px;
    width: 90%;
    max-width: 440px;
    overflow: hidden;
    font-size: 16px;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 16px;

    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transition-property: opacity,-webkit-transform;
    transition-property: opacity,-webkit-transform;
    transition-property: transform,opacity;
    transition-property: transform,opacity,-webkit-transform;
    z-index: 999999;
    max-height: 90%;
    overflow: initial;
}
.close_dialog{
  text-align: center;
    background: #000;
    color: #fff;
    cursor: pointer;
  position: absolute;
    right: -14px;
    top: -14px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border: 2px solid #fff;
    height: 28px;
    line-height: 21px;
    width: 28px;
    z-index: 1000;

}
.tip_section{
  margin-bottom: 30px;
}
.tip_section:last-child{
  margin-bottom: 0px !important;
}
.step_title{
  margin-bottom: 4px !important;
}
.login_tips{
  padding: 20px;
}
.loginp_title{
  text-align: center;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    padding-bottom: 10px;
}
.loginp_content{
  max-height: 420px;
    overflow-y: auto;
}
.loginp_content p{
  font-size: 14px;
    color: #000;
    margin-bottom: 12px;
}
.loginp_content p a{
  color: #8f8cff;
  text-decoration: none;
}
.loginp_content p a:hover{
  color: #8f8cff;
}
.tip_titles{
  font-weight: bold;
}