#mint h2 {
    margin-bottom: 2rem;
}

#mint .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#mint .confirm-btn {
    width: 100%;
    margin-top: 2rem;
}
