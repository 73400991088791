

#success-page .default-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#success-page #back-btn {
    width: 100%;
    margin-top: 1rem;
}
