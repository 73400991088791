#failed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#failed div {
    background: #ff4c4c;
    border-radius: 100%;
    border: 2px solid #ff4c4c;
    display: flex;
    width: 3rem;
    justify-content: center;
    align-items: center;
    aspect-ratio: 1;
    color: #ffffff;
    font-weight: bold;
}

#failed span {
    margin-top: 1rem;
    font-weight: bold;
}
